import React, { useState, useEffect } from 'react';
import './App.css';
import Login from './Login';
import Header from './components/Header';
import Home from './components/Home';
import Dashboard from './components/Dashboard';
import Accounts from './components/Accounts';
import Balances from './components/Balances';
import CostSelector from './components/CostSelector'; 

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [currentPage, setCurrentPage] = useState('home');

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    }
  }, []);

  const handleLoginSuccess = () => {
    setIsAuthenticated(true);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setCurrentPage('home');
  };

  const handleTokenExpired = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('token');
  };

  const renderPage = () => {
    switch (currentPage) {
      case 'home':
        return <Home />;
      case 'dashboard':
        return <Dashboard />;
      case 'accounts':
        return <Accounts onTokenExpired={handleTokenExpired} />;
      case 'balances':
        return <Balances onTokenExpired={handleTokenExpired} />;
      case 'costs':
        return <CostSelector onTokenExpired={handleTokenExpired} />;
        return <Home />;
    }
  };

  return (
    <div className="App">
      {isAuthenticated ? (
        <>
          <Header setCurrentPage={setCurrentPage} handleLogout={handleLogout} />
          <main>
            {renderPage()}
          </main>
        </>
      ) : (
        <Login onLoginSuccess={handleLoginSuccess} />
      )}
    </div>
  );
}

export default App;
