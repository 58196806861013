import React from 'react';

const Header = ({ setCurrentPage, handleLogout }) => {
  return (
    <header style={styles.header}>
      <h1>Beancy Dashboard</h1>
      <nav style={styles.nav}>
        <button onClick={() => setCurrentPage('home')} style={styles.button}>Home</button>
        <button onClick={() => setCurrentPage('dashboard')} style={styles.button}>Dashboard</button>
        <button onClick={() => setCurrentPage('accounts')} style={styles.button}>Accounts</button>
        <button onClick={() => setCurrentPage('balances')} style={styles.button}>Balances</button>
        <button onClick={() => setCurrentPage('costs')} style={styles.button}>Costs</button> {/* Nuevo botón agregado */}
      </nav>
      <button onClick={handleLogout} style={styles.logoutButton}>Logout</button>
    </header>
  );
};

const styles = {
  header: {
    backgroundColor: '#282c34',
    padding: '10px',
    color: 'white',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  nav: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    margin: '0 10px',
    backgroundColor: '#61dafb',
    color: '#282c34',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
  logoutButton: {
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    padding: '10px',
    cursor: 'pointer',
    borderRadius: '4px',
  },
};

export default Header;
