import React from 'react';

const Home = () => {
  return (
    <div>
      <h2>Welcome to Beancy Dashboard</h2>
      <p>This is the home page of your React application.</p>
    </div>
  );
};

export default Home;
