import React, { useState, useEffect } from 'react';

const Accounts = ({ onTokenExpired }) => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedMMType, setSelectedMMType] = useState('all');
  const [searchQuery, setSearchQuery] = useState(''); // Estado para la búsqueda

  useEffect(() => {
    const fetchAccounts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/accounts', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setAccounts(data);
        } else if (response.status === 401) {
          const errorData = await response.json();
          if (errorData.msg === "Token has expired") {
            onTokenExpired();
          } else {
            setError('Failed to fetch accounts');
          }
        } else {
          setError('Failed to fetch accounts');
        }
      } catch (err) {
        setError('An error occurred while fetching accounts');
      } finally {
        setLoading(false);
      }
    };

    fetchAccounts();
  }, [onTokenExpired]);

  const truncateToken = (token) => {
    if (!token) return 'N/A';
    if (token.length <= 10) return token;
    return `${token.substring(0, 5)}...${token.substring(token.length - 5)}`;
  };

  // Extraer los valores únicos de "MM Type" de las cuentas
  const mmTypes = Array.from(new Set(accounts.map(account => account.mm_type)));

  // Filtrar cuentas según el tipo seleccionado o la búsqueda
  const filteredAccounts = accounts.filter(account => {
    const matchesType = selectedMMType === 'all' || account.mm_type === selectedMMType;
    const matchesSearch = account.identifier.toLowerCase().includes(searchQuery.toLowerCase());
    return matchesType && matchesSearch;
  });

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
    setSelectedMMType('all'); // Cambiar a 'All' cuando se usa la búsqueda
  };

  if (loading) {
    return <p>Loading accounts...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div style={styles.container}>
      <div style={styles.titleContainer}>
        <h2 style={styles.title}>Accounts</h2>
        <span style={styles.countNumber}>({filteredAccounts.length})</span>
      </div>
      
      <input
        type="text"
        placeholder="Search by identifier..."
        value={searchQuery}
        onChange={handleSearch}
        style={styles.searchInput}
      />

      <div style={styles.tabsContainer}>
        <button
          style={selectedMMType === 'all' ? styles.activeTab : styles.tab}
          onClick={() => setSelectedMMType('all')}
        >
          All
        </button>
        {mmTypes.map((type, index) => (
          <button
            key={index}
            style={selectedMMType === type ? styles.activeTab : styles.tab}
            onClick={() => setSelectedMMType(type)}
          >
            {type}
          </button>
        ))}
      </div>

      {filteredAccounts.length === 0 ? (
        <p>No accounts available.</p>
      ) : (
        <div>
          {filteredAccounts.map((account, index) => (
            <div key={index} style={styles.accountContainer}>
              <h3 style={styles.identifier}>Identifier: {account.identifier}</h3>
              <p style={styles.info}><strong>Symbol:</strong> {account.symbol}</p>
              <p style={styles.info}><strong>Exchange:</strong> {account.exchange}</p>
              <p style={styles.info}><strong>MM Type:</strong> {account.mm_type}</p>
              {account.api_key ? (
                <div>
                  <p style={styles.info}><strong>API Key:</strong> {truncateToken(account.api_key)}</p>
                  <p style={styles.info}><strong>Secret Key:</strong> {truncateToken(account.secret_key)}</p>
                  <p style={styles.info}><strong>Parameter 1:</strong> {truncateToken(account.parameter_1)}</p>
                </div>
              ) : (
                <div>
                  <h4 style={styles.subAccountTitle}>Sub Accounts:</h4>
                  {account.accounts.map((subAccount, subIndex) => (
                    <div key={subIndex} style={styles.subAccountContainer}>
                      <p style={styles.subInfo}><strong>Subidentifier:</strong> {subAccount.subidentifier}</p>
                      <p style={styles.subInfo}><strong>API Key:</strong> {truncateToken(subAccount.api_key)}</p>
                      <p style={styles.subInfo}><strong>Secret Key:</strong> {truncateToken(subAccount.secret_key)}</p>
                      <p style={styles.subInfo}><strong>Password:</strong> {truncateToken(subAccount.password)}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '800px',
    margin: '0 auto',
    color: '#f5f5f5', // Color de texto para el tema oscuro
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  title: {
    marginRight: '10px',
    fontSize: '24px',
  },
  countNumber: {
    color: '#61dafb', // Color de acento para el tema oscuro
    fontWeight: 'bold',
    fontSize: '20px',
    transition: 'color 0.3s ease, transform 0.3s ease',
  },
  searchInput: {
    padding: '10px',
    width: '100%',
    marginBottom: '20px',
    borderRadius: '5px',
    border: '1px solid #444', // Borde más oscuro
    fontSize: '16px',
    backgroundColor: '#333', // Fondo oscuro
    color: '#f5f5f5', // Texto claro
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  tab: {
    padding: '10px 20px',
    margin: '0 5px',
    border: '1px solid #444', // Borde más oscuro
    borderRadius: '5px',
    backgroundColor: '#555', // Fondo oscuro para tabs inactivos
    color: '#f5f5f5', // Texto claro
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  activeTab: {
    padding: '10px 20px',
    margin: '0 5px',
    border: '1px solid #61dafb', // Borde azul para el tab activo
    borderRadius: '5px',
    backgroundColor: '#61dafb', // Fondo azul para el tab activo
    color: '#1e1e1e', // Texto oscuro en el tab activo
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  accountContainer: {
    border: '1px solid #444', // Borde más oscuro
    padding: '15px',
    margin: '15px 0',
    borderRadius: '8px',
    backgroundColor: '#2c2c2c', // Fondo oscuro para el contenedor de cuentas
    textAlign: 'left',
  },
  subAccountContainer: {
    padding: '10px',
    margin: '10px 0',
    borderRadius: '4px',
    backgroundColor: '#3b3b3b', // Fondo más oscuro para subcuentas
    marginLeft: '20px',
  },
  identifier: {
    fontSize: '18px',
    marginBottom: '10px',
    color: '#f5f5f5', // Texto claro para el identificador
  },
  info: {
    margin: '5px 0',
    fontSize: '16px',
    color: '#ddd', // Texto claro para la información general
  },
  subAccountTitle: {
    marginTop: '15px',
    marginBottom: '10px',
    fontSize: '17px',
    color: '#61dafb', // Color de acento para subcuentas
  },
  subInfo: {
    margin: '5px 0',
    fontSize: '15px',
    color: '#ddd', // Texto claro para la información de subcuentas
  },
};


export default Accounts;
