import React, { useState, useEffect, useCallback } from 'react';
import MetatraderBalances from './MetatraderBalances';
import LastValues from './LastValues'; // Importa el componente LastValues

const Balances = ({ onTokenExpired }) => {  // Añadido onTokenExpired como prop
    const [latestBalances, setLatestBalances] = useState({
      totalBalance: null,
      bnbBalance: null,
      brokerBalance: null,
      accumulate: null,
      date: null,
    });
  
    const updateLatestBalances = (balances) => {
      if (balances.length > 0) {
        const latestBalance = balances[balances.length - 1];
        setLatestBalances({
          totalBalance: latestBalance.total_balance,
          bnbBalance: latestBalance.bnb_total_balance,
          brokerBalance: latestBalance.broker_total_balance,
          accumulate: latestBalance.accumulate,
          date: new Date(latestBalance.requested_at),
        });
      }
    };
  
    return (
      <div style={styles.container}>
        <h2 style={styles.title}>Balances</h2>
  
        <LastValues balances={latestBalances} />
  
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Balances de Metatrader</h3>
          <MetatraderBalances onUpdateLatestBalances={updateLatestBalances} onTokenExpired={onTokenExpired} /> {/* Añadido onTokenExpired */}
        </div>
      </div>
    );
  };
  

const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    color: '#f5f5f5',
    backgroundColor: '#1e1e1e',
    borderRadius: '8px',
  },
  title: {
    fontSize: '28px',
    marginBottom: '20px',
    color: '#61dafb',
  },
  section: {
    marginBottom: '40px',
  },
  sectionTitle: {
    fontSize: '24px',
    marginBottom: '15px',
    color: '#61dafb',
  },
};

export default Balances;
