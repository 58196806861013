import React, { useState, useEffect } from 'react';
import CostData from './CostData';

const CostSelector = ({ onTokenExpired }) => {
  const [databases, setDatabases] = useState([]);
  const [selectedDatabase, setSelectedDatabase] = useState('');
  
  useEffect(() => {
    const fetchDatabases = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch('/api/cost-collections', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch databases.');
        }

        const data = await response.json();
        const uniqueDatabases = [...new Set(data.map(item => item.split('.')[0]))];
        setDatabases(uniqueDatabases);
        if (uniqueDatabases.length > 0) {
          setSelectedDatabase(uniqueDatabases[0]);
        }
      } catch (err) {
        console.error(err.message);
      }
    };

    fetchDatabases();
  }, []);

  const handleDatabaseChange = (e) => {
    setSelectedDatabase(e.target.value);
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Select Cost Data</h2>
      <div style={styles.selectorContainer}>
        <label htmlFor="database">Database:</label>
        <select id="database" value={selectedDatabase} onChange={handleDatabaseChange} style={styles.select}>
          {databases.map((db, index) => (
            <option key={index} value={db}>
              {db}
            </option>
          ))}
        </select>
      </div>

      {selectedDatabase && <CostData database={selectedDatabase} collection="cost" />}
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#1e1e1e',
    color: '#f5f5f5',
    borderRadius: '8px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#61dafb',
  },
  selectorContainer: {
    marginBottom: '20px',
  },
  select: {
    marginLeft: '10px',
    padding: '5px',
    fontSize: '16px',
  },
};

export default CostSelector;
