import React from 'react';

const LastValues = ({ balances }) => {
  const { totalBalance, bnbBalance, brokerBalance, accumulate, date } = balances;

  const daysAgo = date ? Math.floor((new Date() - date) / (1000 * 60 * 60 * 24)) : null;

  return (
    <div style={styles.latestBalancesContainer}>
      <div style={styles.balanceBox}>
        <h4 style={styles.balanceTitle}>Saldo Total</h4>
        <p style={styles.balanceValue}>
          {totalBalance !== null ? totalBalance.toLocaleString() : 'Cargando...'}
        </p>
        <p style={styles.balanceDate}>
          {date ? date.toLocaleDateString('es-ES') : ''}
        </p>
        {daysAgo !== null && daysAgo > 0 && (
          <p style={styles.balanceDaysAgo}>
            Hace {daysAgo} día{daysAgo > 1 ? 's' : ''}
          </p>
        )}
      </div>
      <div style={styles.balanceBox}>
        <h4 style={styles.balanceTitle}>Saldo BNB</h4>
        <p style={styles.balanceValue}>
          {bnbBalance !== null ? bnbBalance.toLocaleString() : 'Cargando...'}
        </p>
        <p style={styles.balanceDate}>
          {date ? date.toLocaleDateString('es-ES') : ''}
        </p>
        {daysAgo !== null && daysAgo > 0 && (
          <p style={styles.balanceDaysAgo}>
            Hace {daysAgo} día{daysAgo > 1 ? 's' : ''}
          </p>
        )}
      </div>
      <div style={styles.balanceBox}>
        <h4 style={styles.balanceTitle}>Saldo del Broker</h4>
        <p style={styles.balanceValue}>
          {brokerBalance !== null ? brokerBalance.toLocaleString() : 'Cargando...'}
        </p>
        <p style={styles.balanceDate}>
          {date ? date.toLocaleDateString('es-ES') : ''}
        </p>
        {daysAgo !== null && daysAgo > 0 && (
          <p style={styles.balanceDaysAgo}>
            Hace {daysAgo} día{daysAgo > 1 ? 's' : ''}
          </p>
        )}
      </div>
      <div style={styles.balanceBox}>
        <h4 style={styles.balanceTitle}>Acumulado</h4>
        <p style={styles.balanceValue}>
          {accumulate !== null ? accumulate.toLocaleString() : 'Cargando...'}
        </p>
        <p style={styles.balanceDate}>
          {date ? date.toLocaleDateString('es-ES') : ''}
        </p>
        {daysAgo !== null && daysAgo > 0 && (
          <p style={styles.balanceDaysAgo}>
            Hace {daysAgo} día{daysAgo > 1 ? 's' : ''}
          </p>
        )}
      </div>
    </div>
  );
};

const styles = {
  latestBalancesContainer: {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '40px',
  },
  balanceBox: {
    backgroundColor: '#282c34',
    padding: '20px',
    borderRadius: '8px',
    textAlign: 'center',
    minWidth: '200px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  balanceTitle: {
    fontSize: '18px',
    color: '#61dafb',
    marginBottom: '10px',
  },
  balanceValue: {
    fontSize: '24px',
    color: '#f5f5f5',
    marginBottom: '5px',
  },
  balanceDate: {
    fontSize: '12px',
    color: '#aaa',
  },
  balanceDaysAgo: {
    fontSize: '12px',
    color: '#ffcc00',
  },
};

export default LastValues;
