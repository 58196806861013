import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Registrar los componentes necesarios para Chart.js
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const CostData = ({ database, collection }) => {
  const [costData, setCostData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCostData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`/api/cost-collections/${database}/${collection}`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch cost data.');
        }

        const data = await response.json();
        setCostData(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCostData();
  }, [database, collection]);

  if (loading) {
    return <p>Cargando datos de costo...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // Preparar los datos para las gráficas
  const timestamps = costData.map(item => new Date(item.timestamp * 1000).toLocaleDateString('es-ES'));

  const totalAmounts = costData.map(item => {
    const balance = item.balances?.find(b => b.total_amount);
    return balance ? parseFloat(balance.total_amount.split(' ')[0]) : 0;
  });

  const amountLastOrder = costData.map(item => item.fees?.amount_last_order || 0);
  const costInFees = costData.map(item => item.fees?.cost_in_fees || 0);
  const percentageFees = costData.map(item => item.fees?.percentage_fees || 0);
  const priceSymbol = costData.map(item => parseFloat(item.price_symbol) || 0);
  const symbol = costData.length > 0 ? costData[0].symbol : ''; // Usar el primer símbolo de la lista de datos

  const createChartData = (label, data, borderColor, backgroundColor) => ({
    labels: timestamps,
    datasets: [
      {
        label,
        data,
        borderColor,
        backgroundColor,
        fill: true,
        tension: 0.1,
      },
    ],
  });

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#f5f5f5',
        },
      },
      title: {
        display: true,
        color: '#f5f5f5',
      },
    },
    scales: {
      x: {
        ticks: {
          color: '#f5f5f5',
          autoSkip: true,
          maxTicksLimit: 10, // Reduce el número de etiquetas en el eje X
        },
        grid: {
          color: '#444',
        },
      },
      y: {
        ticks: {
          color: '#f5f5f5',
        },
        grid: {
          color: '#444',
        },
        beginAtZero: true,
      },
    },
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.title}>Cost Data for {collection}</h2>
      
      <div style={styles.chartContainer}>
        <Line
          data={createChartData('Total Amount (USDT)', totalAmounts, '#61dafb', 'rgba(97, 218, 251, 0.2)')}
          options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: 'Total Amount (USDT)' } } }}
        />
      </div>
      
      <div style={styles.chartContainer}>
        <Line
          data={createChartData('Amount Last Order', amountLastOrder, '#34ebba', 'rgba(52, 235, 186, 0.2)')}
          options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: 'Amount Last Order' } } }}
        />
      </div>
      
      <div style={styles.chartContainer}>
        <Line
          data={createChartData('Cost in Fees', costInFees, '#ffcc00', 'rgba(255, 204, 0, 0.2)')}
          options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: 'Cost in Fees' } } }}
        />
      </div>
      
      <div style={styles.chartContainer}>
        <Line
          data={createChartData('Percentage Fees', percentageFees, '#ff6666', 'rgba(255, 102, 102, 0.2)')}
          options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: 'Percentage Fees' } } }}
        />
      </div>
      
      <div style={styles.chartContainer}>
        <Line
          data={createChartData(`Price Symbol (${symbol})`, priceSymbol, '#a52a2a', 'rgba(165, 42, 42, 0.2)')}
          options={{ ...options, plugins: { ...options.plugins, title: { ...options.plugins.title, text: `Price Symbol (${symbol})` } } }}
        />
      </div>
    </div>
  );
};

const styles = {
  container: {
    padding: '20px',
    backgroundColor: '#1e1e1e',
    color: '#f5f5f5',
    borderRadius: '8px',
    maxWidth: '1000px',
    margin: '0 auto',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
    color: '#61dafb',
  },
  chartContainer: {
    marginTop: '20px',
    marginBottom: '40px', // Espacio entre gráficos
  },
};

export default CostData;

