import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { format } from 'date-fns';
import { es } from 'date-fns/locale'; // Asegúrate de usar la localización en español
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';

// Registrar todos los componentes necesarios para Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

const MetatraderBalances = ({ onUpdateLatestBalances, onTokenExpired }) => {
    const [balances, setBalances] = useState([]);
    const [filteredBalances, setFilteredBalances] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [activeButton, setActiveButton] = useState('ALL');
  
    useEffect(() => {
      // Solo hacemos la petición si no hemos cargado balances anteriormente
      if (balances.length === 0) {
        const fetchBalances = async () => {
          try {
            const token = localStorage.getItem('token');
            const response = await fetch('/api/metatrader-broker-balances', {
              headers: {
                'Authorization': `Bearer ${token}`,
              },
            });
  
            if (response.status === 401) {
              // Si el token ha expirado o es inválido, llamamos a onTokenExpired
              onTokenExpired();
              return;
            }
  
            if (!response.ok) {
              throw new Error('Fallo al obtener los balances de Metatrader.');
            }
  
            const data = await response.json();
            data.sort((a, b) => new Date(a.requested_at) - new Date(b.requested_at)); // Ordenar por fecha
            setBalances(data);
            setFilteredBalances(data);
            onUpdateLatestBalances(data); // Actualiza los balances más recientes en el componente padre
          } catch (err) {
            setError(err.message);
          } finally {
            setLoading(false);
          }
        };
  
        fetchBalances();
      }
    }, [balances, onUpdateLatestBalances, onTokenExpired]);

  const filterDataByRange = (range) => {
    if (range === 'ALL') {
      setFilteredBalances(balances);
      setActiveButton('ALL');
      return;
    }

    const now = new Date();
    let startDate;
    switch (range) {
      case '1M':
        startDate = new Date(now.setMonth(now.getMonth() - 1));
        break;
      case '3M':
        startDate = new Date(now.setMonth(now.getMonth() - 3));
        break;
      case '6M':
        startDate = new Date(now.setMonth(now.getMonth() - 6));
        break;
      case 'YTD':
        startDate = new Date(now.getFullYear(), 0, 1);
        break;
      case '1Y':
        startDate = new Date(now.setFullYear(now.getFullYear() - 1));
        break;
      default:
        startDate = null;
    }

    if (startDate) {
      const filteredData = balances.filter(balance => {
        const date = new Date(balance.requested_at);
        return date >= startDate;
      });
      setFilteredBalances(filteredData);
    }
    setActiveButton(range);
  };

  if (loading) {
    return <p>Cargando balances...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const labels = filteredBalances.map(balance => {
    const date = new Date(balance.requested_at);
    return isNaN(date) ? 'Fecha inválida' : format(date, 'dd/MM/yyyy', { locale: es });
  });

  // Gráficas de las diferentes métricas
  const dataTotalBalance = {
    labels,
    datasets: [
      {
        label: 'Saldo Total',
        data: filteredBalances.map(balance => balance.total_balance),
        borderColor: '#61dafb',
        backgroundColor: 'rgba(97, 218, 251, 0.2)',
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#61dafb',
        pointHoverBackgroundColor: '#61dafb',
        pointHoverBorderColor: '#ffffff',
        fill: true,
        tension: 0,
      },
    ],
  };

  const dataBNBBalance = {
    labels,
    datasets: [
      {
        label: 'Saldo Total BNB',
        data: filteredBalances.map(balance => balance.bnb_total_balance),
        borderColor: '#34ebba',
        backgroundColor: 'rgba(52, 235, 186, 0.2)',
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#34ebba',
        pointHoverBackgroundColor: '#34ebba',
        pointHoverBorderColor: '#ffffff',
        fill: true,
        tension: 0,
      },
    ],
  };

  const dataBrokerBalance = {
    labels,
    datasets: [
      {
        label: 'Saldo Total del Broker',
        data: filteredBalances.map(balance => balance.broker_total_balance),
        borderColor: '#ffcc00',
        backgroundColor: 'rgba(255, 204, 0, 0.2)',
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#ffcc00',
        pointHoverBackgroundColor: '#ffcc00',
        pointHoverBorderColor: '#ffffff',
        fill: true,
        tension: 0,
      },
    ],
  };

  const dataAccumulate = {
    labels,
    datasets: [
      {
        label: 'Acumulado',
        data: filteredBalances.map(balance => balance.accumulate),
        borderColor: '#ff6666',
        backgroundColor: 'rgba(255, 102, 102, 0.2)',
        pointBackgroundColor: '#ffffff',
        pointBorderColor: '#ff6666',
        pointHoverBackgroundColor: '#ff6666',
        pointHoverBorderColor: '#ffffff',
        fill: true,
        tension: 0,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        labels: {
          color: '#f5f5f5',
        },
      },
      title: {
        display: true,
        text: 'Balances de Metatrader a lo largo del tiempo',
        color: '#f5f5f5',
      },
      tooltip: {
        mode: 'index',
        intersect: false,
      },
    },
    hover: {
      mode: 'index',
      intersect: false,
    },
    scales: {
      x: {
        ticks: {
          color: '#f5f5f5',
          maxRotation: 45,
          minRotation: 45,
        },
        grid: {
          color: '#444',
        },
      },
      y: {
        beginAtZero: false,
        ticks: {
          color: '#f5f5f5',
        },
        grid: {
          color: '#444',
        },
      },
    },
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.buttonsContainer}>
        {['1M', '3M', '6M', 'YTD', '1Y', 'ALL'].map(range => (
          <button
            key={range}
            style={activeButton === range ? styles.buttonActive : styles.button}
            onClick={() => filterDataByRange(range)}
          >
            {range}
          </button>
        ))}
      </div>
      <p style={styles.dateText}>
        {filteredBalances.length > 0
          ? `${format(new Date(filteredBalances[0].requested_at), 'dd/MM/yyyy', { locale: es })} - ${format(new Date(filteredBalances[filteredBalances.length - 1].requested_at), 'dd/MM/yyyy', { locale: es })}`
          : 'Mostrando todos los datos'}
      </p>
      <div style={styles.chartContainer}>
        <Line data={dataTotalBalance} options={options} />
      </div>
      <div style={styles.chartContainer}>
        <Line data={dataBNBBalance} options={options} />
      </div>
      <div style={styles.chartContainer}>
        <Line data={dataBrokerBalance} options={options} />
      </div>
      <div style={styles.chartContainer}>
        <Line data={dataAccumulate} options={options} />
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '900px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#1e1e1e',
    borderRadius: '8px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  button: {
    margin: '0 10px',
    padding: '10px',
    color: '#282c34',
    backgroundColor: '#61dafb',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  buttonActive: {
    margin: '0 10px',
    padding: '10px',
    color: '#ffffff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  dateText: {
    color: '#f5f5f5',
    textAlign: 'center',
    marginBottom: '20px',
  },
  chartContainer: {
    marginTop: '20px',
  },
};

export default MetatraderBalances;
